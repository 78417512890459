import { fetcher } from '@nick-mazuk/lib/fetcher'
import useSWR from 'swr'

import type { ParsedTrack } from 'lib/spotify'

const REFRESH_INTERVAL = 30000

type YouTubeData = {
    viewCount: string
    videoCount: string
    subscriberCount: string
    commentCount: string
    hiddenSubscriberCount: string
}

export const useTopTracks = (): ParsedTrack[] | undefined => {
    const { data } = useSWR('/api/spotify/top-tracks', fetcher)
    return data?.tracks as ParsedTrack[] | undefined
}

export const useCurrentlyPlaying = (): ParsedTrack | undefined => {
    const { data } = useSWR('/api/spotify/currently-playing', fetcher, {
        refreshInterval: REFRESH_INTERVAL,
    })
    return data?.track as ParsedTrack | undefined
}

export const useRecentlyPlayed = (): ParsedTrack[] | undefined => {
    const { data } = useSWR('/api/spotify/recently-played', fetcher, {
        refreshInterval: REFRESH_INTERVAL,
    })
    return data?.tracks as ParsedTrack[] | undefined
}

export const useWebsiteViews = (): number | undefined => {
    const { data } = useSWR('/api/data/website-data', fetcher)
    return data?.pageViews as number | undefined
}

export const useAboutPageViews = (): number | undefined => {
    const { data } = useSWR('/api/data/about-data', fetcher)
    return data?.pageViews as number | undefined
}

export const useYouTubeAnalytics = (): YouTubeData | undefined => {
    const { data } = useSWR('/api/data/youtube-data', fetcher)
    return data as YouTubeData | undefined
}
